import React, {useState,useEffect, useRef} from "react";
import crossIcon from "./../img/icon-cross.svg";
import { useAppContext } from "./../libs/contextLib";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import {ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import {
  readDataAWS,
getCarBrandsAWS,
getPopularCarBrandsAWS,
getBrandModelsAWS
} from "./../libs/lambdaLib";

let options = ["Lease"]

export default function AddAssetForm(props) {

    const {
        company,
        username,
        attributes,
        UUID,
        language,
        schemes,
      } = useAppContext();

      const [tempAttributes,setTempAttributes] = useState({})
      const [tempUUID,setTempUUID] = useState("")
      const [formAttributes,setFormAttributes] = useState(<></>)
      const [plate,setPlate] = useState("")
      const [carBrands,setCarBrands] = useState([])
      const [selectedBrand,setSelectedBrand] = useState("")
      const [popularCarBrands,setPopularCarBrands] = useState([])
      const [carModels,setCarModels] = useState([])
  const [dropdownClass, setDrowndownClass] = useState("dropdown__assign");
  const [currentScheme, setCurrentScheme] = useState(0);
  const [mandatoryAttributes,setMandatoryAttributes] = useState(false)


    const  onChangeCheck = (attr,value) => {
        setTempAttributes((prev) => {
            const copy = {...prev};
            copy[attr] =  value;
            console.log(copy);
            return copy;
        })
    }

    useEffect(()=> {
      getBrands();
    },[])


    const getBrands = async () => {

      const data =   await getCarBrandsAWS()
      let brands = readDataAWS(data).payload.brands
      setCarBrands(brands.sort());
      await getPopularBrands();
    }

      const getPopularBrands = async () => {

        const data =   await getPopularCarBrandsAWS()
        let brands = readDataAWS(data).payload.brands
        setPopularCarBrands(brands.sort());

      }


      const getModels= async () => {

        try{
        const data =   await getBrandModelsAWS(selectedBrand)
        let models = readDataAWS(data).payload.models
        setCarModels(models.sort());}
        catch(e){


        }

      }



    useEffect(() => {
        let carBrandsCopy = [...carBrands]
      for (let i = popularCarBrands.length-1; i >=0; i--) {
        const element = popularCarBrands[i];
        carBrandsCopy.splice(carBrandsCopy.indexOf(element), 1);
        carBrandsCopy.unshift(element);
      }
      console.log(carBrandsCopy)
      setCarBrands(carBrandsCopy);

    },[popularCarBrands])

    useEffect(() => {
      console.log("brand");
      console.log(selectedBrand);
      if(selectedBrand && selectedBrand !== ""){
        getModels(selectedBrand);
      }

    },[selectedBrand])

    useEffect(() => {
      let mandat = false;
      if(attributes){
        mandat = true;
        for (let i = 0; i < attributes.length; i++) {
          const element = attributes[i];
          if(tempAttributes[element.attribute] && element.scheme && element.scheme != schemes[currentScheme]){
            setTempAttributes((prev) => {
              const copy = {...prev};
              delete copy[element.attribute];
              return copy;})
        }
        
          if(element.mandatory && ( (schemes && element.scheme && element.scheme == schemes[currentScheme]) || (!schemes || !element.scheme)) ){
            if(tempAttributes[element.attribute]){

          }
          else{
            mandat = false;
          }
        }
      }
    }
  if(tempUUID){

  }
else{
  mandat = false;

}
setMandatoryAttributes(mandat)},

[tempAttributes,tempUUID,,currentScheme])

  useEffect(() => {
    if(attributes){
        let buttons = [<></>];
        if(props.license){

        }
        else{   const uuidForm= (
          <div class={`form__control`}>
          <label for="email">{UUID}*</label>
          <input type="text" onChange={(e) => setTempUUID(e.target.value)} value={tempUUID} />
        </div>
      );
      buttons.push(uuidForm)
    }

        for (let i = 0; i < attributes.length; i++) {
          const element = attributes[i];
          if( (element.default  && ( !element.scheme || (schemes && element.scheme && element.scheme == schemes[currentScheme] )) ) || ( schemes && element.scheme && element.scheme == schemes[currentScheme])){
            if(element.attribute === "brand"){
              if(props.license){
                const button = (
                  <div class={`form__control`}>
                  <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                  <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

                </div>
                );
                buttons.push(button);
              }else{
                const typeahead = <div class={`form__control`}>
                                    <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              options={carBrands}
                              placeholder="Merk"
                              paginationText="Laad meer"
                              maxResults={20}
                              onChange={(selected) => {
                                setSelectedBrand(selected[0])
                                onChangeCheck(element.attribute,selected[0])
                              }}
                              onInputChange={(selected) => {
                                onChangeCheck(element.attribute,selected)
                              }}
                                /></div>
           buttons.push(typeahead);
              }


            }
            else if(element.attribute === "model"){
              if(props.license){
                const button = (
                  <div class={`form__control`}>
                  <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                  <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

                </div>
                );
                buttons.push(button);
              }
              else{
                const typeahead =                       <div class={`form__control`}>
                                    <label for="email">{element.attribute}{element.mandatory?"*":""}</label>

                <Typeahead
                                              id="basic-typeahead-single"
                                              labelKey="name"
                                              options={carModels}
                                              placeholder="Model"
                                              paginationText="Laad meer"
                                              maxResults={20}
                                              onChange={(selected) => {
                                                onChangeCheck(element.attribute,selected[0])
                                              }}
                                              onInputChange={(selected) => {
                                                onChangeCheck(element.attribute,selected)
                                              }}
                                              /></div>
               buttons.push(typeahead);
              }


                        }
                        else if(element.type === "Number"){
            const button = (
                <div class={`form__control`}>
                  <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                  <input type="number" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

              </div>
              );
              buttons.push(button);
            }
            else if(element.type === "List"){


              if(element.list.length > 3 ){
                const typeahead = 
                <div class={`form__control`}>
                <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  options={element.list}
                  placeholder={"Selecteer"}
                  paginationText="Laad meer"
                  maxResults={20}
                  onChange={(selected) => {
                    onChangeCheck(element.attribute,selected[0])
                  }}
                  onInputChange={(selected) => {
                    onChangeCheck(element.attribute,selected)
                  }}
                  /></div>
                buttons.push(typeahead);
              }
              else{
                let toggles = []
                
                toggles.push()
                for (let j = 0; j < element.list.length ; j++) {
                  toggles.push(<ToggleButton className={"button"} value={element.list[j]}>{element.list[j]}</ToggleButton>)
                }
                buttons.push( 
                  <div class={`form__control`}>
                <label for="email">{element.attribute}{element.mandatory?"*":""}</label> <ToggleButtonGroup    value={tempAttributes[element.attribute]} name={"zones"}  type="radio" size="lg" onChange={e => {
                  console.log(e);
                  onChangeCheck(element.attribute,e)
                  }}>
                  {toggles}
                </ToggleButtonGroup></div>)
                  buttons.push( <br />);
              }

              }
            else {
              const button = (
                <div class={`form__control`}>
                  <label for="email">{element.attribute}{element.mandatory?"*":""}</label>
                  <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

              </div>
              );
              buttons.push(button);
            }
          }

        }


    setFormAttributes(buttons);


  }},[attributes,tempUUID,tempAttributes,carModels,carBrands,currentScheme])

  const schemeSelector = () =>{
    let buttons = [<></>];

    if(schemes && schemes.length > 1){
      let button;
        if(schemes.length >3) {

      button = (
        <div
          className={dropdownClass}
          role="group"
          onClick={onClickUserDropdown }
          onChange={(e) => onClickUserSelector(e)}
        >
                {schemes[currentScheme]}
    
      <div className="dropdown__list__assign">
            {schemes.map((scheme,i) => {
              console.log("Dropdown" + i +"and" + currentScheme)
              const isSelect = `${i}` === `${currentScheme}`;
              console.log(isSelect)
    
              return (
                <div className="dropdown__option__assign">
                  <input
                    name="max"
                    type="radio"
                    value={i}
                    id={i}
                    checked={isSelect}
                  />
                  <label for={i}>{schemes[i]}</label>
                </div>
              );
            })}
          </div>
          </div>
      )
        }
        else {
          let toggles = []
                
          toggles.push()
          for (let j = 0; j < schemes.length ; j++) {
            toggles.push(<ToggleButton className={"button"} value={j}>{schemes[j]}</ToggleButton>)
          }
          button = ( 

            <div class={`form__control`}>
           <ToggleButtonGroup    value={currentScheme} name={"zones"}  type="radio" size="lg" onChange={e => {
                        console.log("schemes changed")
            console.log(e)
              setCurrentScheme(e);
            }}>
            {toggles}
          </ToggleButtonGroup></div>)
            buttons.push( <br />);
        }
    buttons.push(
      <h1>Formulier</h1>
    )
    buttons.push(button)
      
  }
  return buttons;
  }





  const handleClose = () => {
    setTempAttributes({});

    props.handleClose();
  }

 const searchPlate = async () => {
  fetch(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${plate}`)
  .then((response) => response.json())
  .then((data) => {
     setTempUUID(data[0].kenteken);
     const item  = {brand: data[0].merk,
                    model : data[0].handelsbenaming,
                    color: data[0].eerste_kleur,
                    license:data[0].kenteken
     }
     item.brand =  item.brand[0].toUpperCase() +  item.brand.slice(1);
     item.model =  item.model[0].toUpperCase() +  item.model.slice(1);
     console.log(item)

     setTempAttributes(item);
  })
  .catch((err) => {
     console.log(err.message);
  });

 }

 const onClickUserDropdown = () => {
  if (dropdownClass === "dropdown__assign") setDrowndownClass("dropdown__assign -open");
  else setDrowndownClass("dropdown__assign");
};

const onClickUserSelector = (e) => {
  console.log(e.target.value);
  setDrowndownClass("dropdown__assign");
  setCurrentScheme(e.target.value);
};



  return (
    <>
      <div className={`model ${props.show ? "-active" : ""}`}>
        <div class="model__container">
          <h1>Manueel toevoegen</h1>
          <div class={`form__control`}>
{/*
          <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          options={carBrands}
          placeholder="Merk"
          paginationText="Laad meer"
          maxResults={20}
        /> */}
  {props.license?     <>       <label>Kenteken</label>
            <input type="text" onChange={(e) => setPlate(e.target.value)} />
          <span
              class="button"
             onClick={ () => {searchPlate()}}
            > Kenteken zoeken</span></>:<></>}
          </div>

  {formAttributes}
  {schemeSelector()}
  {/* {schemes && schemes.length > 1 && <>
    <h1>Schema</h1>
    <div
      className={dropdownClass}
      role="group"
      onClick={onClickUserDropdown }
      onChange={(e) => onClickUserSelector(e)}
    >
            {schemes[currentScheme]}

  <div className="dropdown__list__assign">
        {schemes.map((scheme,i) => {
          console.log("Dropdown" + i +"and" + currentScheme)
          const isSelect = `${i}` === `${currentScheme}`;
          console.log(isSelect)

          return (
            <div className="dropdown__option__assign">
              <input
                name="max"
                type="radio"
                value={i}
                id={i}
                checked={isSelect}
              />
              <label for={i}>{schemes[i]}</label>
            </div>
          );
        })}
      </div>
      </div>
  </>} */}

          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={handleClose}
          />
                            <span
              class={`button -wide ${mandatoryAttributes?"":"-disabled"}`}
             onClick={ () => {props.postManualAsset(tempUUID,tempAttributes)}}
            > Indienen</span>
            </div>
        </div>
    </>
  );
}
