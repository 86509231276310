import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useHistory,   useParams} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {  readDataAWS,getAttributesAWS } from "./libs/lambdaLib";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import Routes from "./Routes";
import "./App.css";
import logo from "./img/logo.svg";
import logout from "./img/icon-logout.svg";

function App() {
  const { id } = useParams();


  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [username, setUsername] = useState(null);
  const [mobileUnauth, setMobileUnauth] = useState(false);
  const [company, setCompany] = useState(null);
  const [UUID,setUUID] = useState(null);
  const [hasQRCode,setHasQRCode] = useState(false);
  const [printer,setPrinter] = useState(false);
  const [labelInfo,setLabelInfo] = useState([]);
  const [schemes,setSchemes] = useState(null);
  const [assetSummary, setAssetSummary]= useState([]);
  const [hamburgerClass, setHamburgerClass]= useState("");
  const [attributes,setAttributes] = useState([]);
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    console.log("OnLoad App.js??");
    console.log(id);
    try {
      const user = await Auth.currentSession();
      setUsername(user.idToken.payload["cognito:username"]);
      setCompany(user.idToken.payload["custom:company"]);
      userHasAuthenticated(true);
    } catch (e) {
      console.log(e);
      if (e !== "No current user") {
        //onError(e);
      }
    }
    finally{
      setIsAuthenticating(false);

    }

  }

  useEffect(() => {
      if(company){
          getAttributes(company);
      }
  },[company])

 const getAttributes = async (comp) => {
    const result = await getAttributesAWS(comp);
    const {payload} = readDataAWS(result);
    setUUID(payload.uuid);
    setHasQRCode(payload.QRcode);
    setAttributes(payload.attributes);
    setSchemes(payload.schemes);
    console.log("schemes");
    console.log(payload.schemes);
    setAssetSummary(payload.assetSummary);
    setLabelInfo(payload.label);
    setPrinter(payload.printer);
 }
  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <>
        <header className="header">
          <div className="container">
            <a href="" className="header__logo">
              <img src={logo} alt="" />
            </a>

             <div className={`hamburger ${hamburgerClass}`} onClick={() => {setHamburgerClass(hamburgerClass === ""?"-active":"")}}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>

        <div class={`mobile-menu  ${hamburgerClass}`}>
          <div class="container">
            {isAuthenticated  && <><a className={`menu__item`} onClick={() => {setHamburgerClass("");
                    history.replace('/reload');
                     setTimeout(() => {
                         history.replace("/cabinet")
                         }, 0)}}>
                        Kasten
                    </a>
                    <a className={`menu__item`} onClick={() => {setHamburgerClass("");
                    history.replace('/reload');
                     setTimeout(() => {
                         history.replace("/parking")
                         }, 0)}}>
                        Parking
                    </a>
                    <a className={`menu__item`} onClick={() => {setHamburgerClass("");
                    history.replace('/reload');
                     setTimeout(() => {
                         history.replace("/list")
                         }, 0)}}>
                        Lijst
                    </a>
                    <a  class="nav__logout" onClick={ () => {setHamburgerClass("");handleLogout();}}>
                <img src={logout} alt=""/>
                Uitloggen <br/>
                {username} <br/>

            </a></>}
            {!isAuthenticated  && <><a className={`menu__item`} onClick={() => {setHamburgerClass("");
                    history.replace('/reload');
                     setTimeout(() => {
                         history.replace(`/login`)
                         }, 0)}}>
                        Inloggen
                    </a>

            </>}

          </div>
        </div>

        <main>
          <section class="section">
            {/* <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <LinkContainer to="/">
              <Navbar.Brand className="font-weight-bold text-muted">
                CoDek
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                {isAuthenticated ? (
                  <>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </>
                ) : (
                  <>
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar> */}
            <AppContext.Provider
              value={{
                isAuthenticated,
                userHasAuthenticated,
                username,
                company,
                setCompany,
                setUsername,
                UUID,
                attributes,
                assetSummary,
                mobileUnauth,
                hasQRCode,
                labelInfo,
                printer,
                schemes
              }}
            >
              <Routes />
            </AppContext.Provider>
          </section>
        </main>
      </>
    )
  );
}

export default App;
