import React, { useState, useEffect, useRef, useCallback } from "react";
import { API } from "aws-amplify";
import { BsArchive, BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import Webcam from "react-webcam";
import { Auth } from "aws-amplify";
import { BrowserBarcodeReader, Result } from '@zxing/library'
import PageVisibility from 'react-page-visibility';

const AWS = require('aws-sdk');
let rekog;

let intervalBarcode;

export default function Home() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const codeReader = new BrowserBarcodeReader()
  const [imgSrcRaw, setImgSrcRaw] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [point, setPoint] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [detectText, setDetectText] = useState("");
  const [canvasSize, setCanvasSize] = useState({width: 480, height: 640});
  const [isBarcode, setIsBarcode] = useState(false);
  const [camVisible, setCamVisible] = useState(false);


  // Is called when capture is made
  const capture = useCallback(
    () => {
      const data = webcamRef.current.getScreenshot();
      setImgSrcRaw(data);
      console.log("camdata");
      const canvas = webcamRef.current.getCanvas();
      const context = canvas.getContext('2d');
      console.log(canvas.width)
      setCanvasSize({width:canvas.width, height: canvas.height})
      context.clearRect(0, 0, canvas.width, canvas.height);
      console.log(data)
      let image;
          image = atob(data.split("data:image/png;base64,")[1]);

      // Unencode image bytes for Rekognition DetectFaces API.
      const length = image.length;
      const imageBytes = new ArrayBuffer(length);
      const ua = new Uint8Array(imageBytes);
      for (var i = 0; i < length; i++) {
        ua[i] = image.charCodeAt(i);
      }
      setImgSrc(ua);

      },
    [webcamRef]
  );


  // Is called when Image is stored locally (setImgSrc)
  useEffect(() =>{
    if(imgSrc){

      const context = canvasRef.current.getContext("2d");


      var blob = new Blob([imgSrc], { type: "image/png" });
      var url = URL.createObjectURL(blob);
      var img = new Image();
      const width = canvasSize.width;
      const height = canvasSize.height;
      img.onload = function(){
        context.drawImage(img, 0, 0)
        if(isBarcode){
          codeReader.decodeFromImage(undefined, imgSrcRaw).then(result => {
            console.log(result)
            setBarcode(result)
          }).catch((err) => {
            console.log(err)
        
          })
        }
        else {
          //This is where AWS gets called so rekog is he aws object.
        const params = {Image: {Bytes: imgSrc}}
        rekog.detectText(params, function(err, response) {
          if (err) {
            console.log(err, err.stack); // handle error if an error occurred
          } else {
            console.log(`Detected Text for`);
            console.log(response.TextDetections);
            let correctVINObject;
            let sanitizedStringVin;
            let lastFiveDigits;

            for (let index = 0; index < response.TextDetections.length; index++) 
            {
              const element = response.TextDetections[index];
              //We need to identify WHAT a VIN is:
              //VIN is  17 characters  in length
              //AWS provides type LINE or WORD in the array. for VIN we should use WORD type
              if(element.Type != "WORD"){continue;}

              //Sometimes VIN numbers have * * too close  to the VIN, making the length 19. This step ensures that we check if there is a * at the beginning and/or end of the string and removes it.

              if(element.DetectedText.length < 17 ){continue;}

              let tempVinString = element.DetectedText;
              if(tempVinString.length == 19)
              {
                console.log("VIN is 19 in length, trying to slice first and last string character.");
                console.log("Before: " + tempVinString);
                tempVinString = tempVinString.slice(1); 
                tempVinString = tempVinString.slice(0, -1); 
                console.log("After: " + tempVinString);
              }

              let lastCharacter = tempVinString.charAt(tempVinString.length - 1);
              let lastCharIsNum = /^\d+$/.test(lastCharacter);
              if(!lastCharIsNum)
              {
                tempVinString = tempVinString.slice(0, -1); 
              }
              if(tempVinString.length != 17){continue;}

              //At this point we are certain the string is 17 characters, we don't know if this is actually the vin but the next step could "sanitize" it.
              //console.log(cleanedVinString);

              let last5 = tempVinString.slice(-5);
              let isnum = /^\d+$/.test(last5);
              console.log("isnum: " + isnum);

              if(!isnum){continue;}

              lastFiveDigits = last5;
              console.log("Last 5 digits: " + last5);
              //Ideally we would ask the user if the VIN is correct or not, or ask if the last 5 are correct.
              //if user says yes then good send request to DB, otherwise take photo again.
              //It's a higher chance of the last 5 being right than the entire VIN to be right, we only need the last 5 characters anyways.
              //this could be set in the function that requests the lookup in the DB

              //If we get here the string has: 17 characters and the last 5 are numerical.
              console.log(tempVinString);
              console.log(element);
              sanitizedStringVin = tempVinString;
              correctVINObject = element;

              correctVINObject.lastFiveDigits = last5;
              correctVINObject.viewOnlyText = sanitizedStringVin;
              break;
            }

            if(correctVINObject == undefined){
              console.log("A.I. was not able to detect a VALID VIN text. Please retake picture.");
            }
            else{
              //correctVINObject.DetectedText = sanitizedStringVin + " | Laatste 5 cijfers: " + lastFiveDigits;
              setPoint(correctVINObject);
            }
            
            //setPoint(response.TextDetections[0]);
            // response.TextDetections.forEach(label => {
            //   console.log(`Detected Text: ${label.DetectedText}`),
            //   console.log(`Type: ${label.Type}`),
            //   console.log(`ID: ${label.Id}`),
            //   console.log(`Parent ID: ${label.ParentId}`),
            //   console.log(`Confidence: ${label.Confidence}`),
            //   console.log(`Polygon: `)
            //   console.log(label.Geometry.Polygon)
            // } 
            // )
          }
      })
        }
   
      //         context.beginPath();
      // context.rect(0.1*width, 0.1*height, 0.8*width, 0.8*height);

      // context.lineWidth = 7;
      // context.strokeStyle = 'black';
      // context.stroke();
      }
    
      img.src = URL.createObjectURL(blob);  

}},[imgSrc])



// Is called when points of square of text is received from AWS
useEffect(() => {
  if(point && !isBarcode){
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
   const width = canvasSize.width;
   const height = canvasSize.height;
   console.log(width);
    // let lowestPoint = {X:,Y};
    // let highestPoint;
    point.Geometry.Polygon.forEach((point,i) => {
      if(i===0){
        context.moveTo((point.X)*width,point.Y*height);

      }
      else {
        context.lineTo((point.X)*width,point.Y*height);
      }
    })
            context.lineTo((point.Geometry.Polygon[0].X)*width,point.Geometry.Polygon[0].Y*height);
            context.lineWidth = 1;

            context.strokeStyle = 'red';
        context.font = "20px Arial";
        context.fillStyle = "red";

            context.fillText(point.DetectedText,
               point.Geometry.BoundingBox.Left*width,
               (point.Geometry.BoundingBox.Top+point.Geometry.BoundingBox.Height < 0.8?point.Geometry.BoundingBox.Top+point.Geometry.BoundingBox.Height+0.1:point.Geometry.BoundingBox.Top-0.1)*height);
            context.stroke();


            setDetectText(point.viewOnlyText + " | Laatste 5 cijfers: " + point.lastFiveDigits)


  }
},[point])


// Is called when user siwtches to barcode
useEffect(()=> {

  if(isBarcode){
   intervalBarcode= setInterval(() => {
    capture();
    },10)
  }
  else{
    clearInterval( intervalBarcode)
  }
},[isBarcode])

// Is called when barcode has been detected
useEffect(() => {
  if(barcode && isBarcode){
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
   const width = canvasSize.width;
   const height = canvasSize.height;
   console.log(barcode.resultPoints[0].x);
    // let lowestPoint = {X:,Y};
    // let highestPoint;
        context.moveTo(barcode.resultPoints[0].x,barcode.resultPoints[0].y);
            context.lineTo(barcode.resultPoints[1].x,barcode.resultPoints[1].y);
            context.lineWidth = 2;

            context.strokeStyle = 'red';
        context.font = "20px Arial";
        context.fillStyle = "red";
        context.stroke();

            setDetectText(barcode.text)


  }
},[barcode])


  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        //TODO: get the pinid!
       // const notes = await loadNotes();
        //setNotes(notes);
        const credentials = await Auth.currentCredentials();
        console.log(credentials);
        rekog = new AWS.Rekognition({
          region: "eu-central-1",
          credentials,
        });
      } catch (e) {
        //onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  // function loadNotes() {
  //   return API.get("notes", "/notes");
  // }


  function renderLander() {
    return (
      <div className="lander">
        <h1>CoDek</h1>
      </div>
    );
  }

  const videoConstraints = {
    screenshotFormat:"image/jpeg",
    facingMode: { ideal: "environment" }


  };

  function renderAuthenticated() {
    return (
      <div className="lander">
        <h1>CoDek</h1>
        <p className="text-muted">Aangemeld</p>
        {/* <div class="form__control toggle">
                                  <input type="checkbox" id="placeEdit" defaultChecked={isBarcode}
                                  onChange={(e) => { setDetectText("");
                                  setIsBarcode((prev) => {
                                    return !prev;
                                  })}
                                  }/>
                                  <label for="placeEdit">
                                      {isBarcode?"Barcode":"VIN"}
                                      <div class="toggle__switch">
                                          <span class="toggle__check"></span>
                                          </div>
                                  </label>
                                  </div>
        <h1>{detectText}</h1>
        <span
              class="button"
             onClick={()=>{setCamVisible(!camVisible)}}
            > Camera Open</span>
        {!imgSrc &&<span
              class="button"
             onClick={ capture}
            > Foto Nemen</span>}
              {imgSrc &&<span
              class="button"
             onClick={() => {setImgSrc(null)}}
            > Probeer opnieuw</span>}
        <div>
        {imgSrc &&      <div hidden={imgSrc && isBarcode }>   <canvas
            ref={canvasRef}
            width={canvasSize.width} height={canvasSize.height}
  /></div> }{camVisible && <div hidden={imgSrc && !isBarcode }> <Webcam
         videoConstraints={videoConstraints}
         screenshotFormat="image/png"
         onClick={() => {console.log("clicked cam")}}
         ref={webcamRef}/>
         </div>}
         </div> */}
      
      </div>
    );
  }

  return (
    <PageVisibility onChange={(isVisible)=>{setCamVisible(isVisible)}}>

    <div className="Home">
      {isAuthenticated ? renderAuthenticated() : renderLander()}
    </div>
    </PageVisibility>

  );
}
