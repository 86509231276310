import { Auth } from "aws-amplify";
const Lambda = require("aws-sdk/clients/lambda");

let lambda;

export async function setCredentialsAWSLambda() {
  const credentials = await Auth.currentCredentials();
  console.log(credentials);
  lambda = new Lambda({
    region: "eu-central-1",
    credentials,
  });
  console.log("Gave lambda?");
  return;
}

export async function resetPasswordAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-reset-password",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function confirmUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-confirm-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getStatesAWS(company, states) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-states",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        states,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function changePlaceAWS(company,chassis,pinID,newPlace,username,parkingName) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-change-place",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        pinStatus: "Parking Assigned",
        newPlace,
        chassis,
        pinID,
        username,
        parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getAttributesAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-attributes",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function insertPinDBAWS(
  car,
  pinID,
  username,
  company,
  existingPin
) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-assign-pin",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        car,
        pinID,
        username,
        company,
        existingPin,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getNewAssetsAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-unassigned-assets",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getChangePlaceSettingsAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-changeplace-settings",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function checkPlaceOccupiedAWS(company,placeName,parkingName) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-check-place-occupied",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        placeName,
        parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getZonesAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-zones",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}
export async function getCompanyPinsAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-pins-company",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        forward: "false",
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCarBrandsAWS() {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-car-brands",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getPopularCarBrandsAWS() {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-popular-car-brands",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getBrandModelsAWS(brand) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-brand-models",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        brand
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getAssetWittebrug(reportingCode,brand) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "fastback-import-n16-single-n20-wittebrug_reportingCode",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        reportingCode,
        brand
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}



export async function addManualAssetAWS(uuid,attributes,username,company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-asset",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        uuid,
        attributes,
        username,
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCabinetsCompanyAWS(company) {
  if (lambda === undefined) {
    console.log("undefined lambda");
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cabinets-company",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(lambda);
  return lambda.invoke(params).promise();
}

export async function getPinsCabinetAWS(cabinet, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cabinet",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        cabinet: cabinet,
        company: company,
      },
    }),
  };
  return lambda.invoke(params).promise();
}
export async function getPictureAWS(chassis, timestamp, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-picture",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        chassis: chassis,
        company,
        timestamp,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getEventsAWS(chassis, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-events",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        chassis,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getEventsPlaceAWS(placeString, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-events",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        placeString,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCarsParkingAWS(company,parkingName) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cars-parking",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function deletePinAWS(pin, state, username, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-delete-pin",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        pinID: pin.pinID,
        chassis: pin.chassis,
        pinStatus: state,
        username: username,
        company: company,
        remove: false,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export function readDataAWS(data) {
  const statusCode = JSON.parse(data.Payload).statusCode;
  const payload = JSON.parse(JSON.parse(data.Payload).body).payLoad;
  const message = JSON.parse(JSON.parse(data.Payload).body).message;
  return { statusCode, payload, message };
}
